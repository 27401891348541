/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../../auth'
import clsx from 'clsx'
import axios from 'axios'
import { DeletEmploye_URL, Employe_URL } from '../../../auth/core/_requests'

const initialValues = {
  email: '',
  name: '',
  phone: '',
}

interface initialValuesObj {
  email: string,
  name: string,
  phone: string,
}


const EmployeeEdit = ({ id, getData, setshow }: { id?: number, getData: Function, setshow: Function }) => {
  const [employee, setEmployee] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [FormikStatus, setFormikStatus] = useState("")
  const stepperRef = useRef<HTMLDivElement | null>(null)

  const { currentUser } = useAuth()

  useEffect(
    () => {
      if (!!id && id != 0) GetData();
      else {
        setLoadingForm(true)
      }
    },
    []
  )

  const GetData = async () => {
    setLoading(true)
    axios
      .get(DeletEmploye_URL + id, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoadingForm(true)
        setLoading(false)
        setEmployee(x['data']['data']['employee'])
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    phone: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Phone is required'),
  })

  const submitStep = async (values: initialValuesObj, actions: FormikValues) => {
    setLoading(true)
    try {
      if (!!id && id != 0)
        axios.post<any>(
          DeletEmploye_URL + id,
          {
            email: values.email,
            name: values.name,
            phone: values.phone,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshow(false)
          setLoading(false)
          getData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
      else
        axios.post<any>(
          Employe_URL,
          {
            email: values.email,
            name: values.name,
            phone: values.phone,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshow(false)
          setLoading(false)
          getData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
    } catch (error) { }
  }

  return (
    <>
      <div className='modal-header pb-0 border-0 w-100'>
        <div className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-start'>
          <label className='form-label fw-bolder text-dark fs-6'>{!id ? 'Add new Employee' : 'Edit Employee : ' + (employee?.name ?? '')}</label>
        </div>
        <div
          onClick={() => {
            setshow(false)
          }}
          className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-end'
          data-bs-dismiss='modal'
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body'>
        <div
          ref={stepperRef}
        >

          {FormikStatus && (
            <div className=' mx-auto w-100 alert alert-danger'>
              <div className='alert-text font-weight-bold'
                dangerouslySetInnerHTML={{ __html: FormikStatus }}
              />
            </div>
          )}

          {!loadingForm && (
            <div className='w-100 text-center mt-20 mb-20'>
              <div className="spinner-grow text-primary" style={{ height: '8rem', width: '8rem' }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {loadingForm &&
            <Formik validationSchema={schema} enableReinitialize={false} initialValues={employee ?? initialValues} onSubmit={submitStep}>
              {({
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form className='mx-auto w-100 pt-6 pb-10' id='kt_create_account_form'>

                  <div className='current' data-kt-stepper-element='content'>
                    <div className='row col-12'>

                      {/* begin::Form group Email */}

                      <div className='fv-row mb-8'>
                        <div className="fv-row">
                          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
                          <input
                            placeholder='Email'
                            type='email'
                            autoComplete='off'
                            value={values.email}
                            onChange={(e) => {
                              console.log(currentUser);
                              setFieldValue("email", e.target.value)
                            }}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.email && errors.email },
                              {
                                'is-valid': touched.email && !errors.email,
                              }
                            )}
                          />
                          {touched.email && errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.email}</span>
                              </div>
                            </div>
                          )}
                        </div>

                      </div>

                      {/* begin::Form group Password */}
                      <div className='fv-row mb-6' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Name</label>
                          <div className='position-relative'>
                            <input
                              type='Name'
                              placeholder='Name'
                              autoComplete='off'
                              value={values.name}
                              onChange={(e) => {
                                setFieldValue("name", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.name && errors.name,
                                },
                                {
                                  'is-valid': touched.name && !errors.name,
                                }
                              )}
                            />
                            {touched.name && errors.name && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.name}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>

                      <div className='fv-row mb-6' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Phone</label>
                          <div className='position-relative'>
                            <input
                              type='Name'
                              placeholder='Name'
                              autoComplete='off'
                              value={values.phone}
                              onChange={(e) => {
                                setFieldValue("phone", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.phone && errors.phone,
                                },
                                {
                                  'is-valid': touched.phone && !errors.phone,
                                }
                              )}
                            />
                            {touched.phone && errors.phone && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.phone}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      {/* end::Form group */}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-6'>
                    <div className='mr-2'>
                      <button
                        onClick={() => {
                          setshow(false)
                        }}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={loading}
                        type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!loading && 'Submit'}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}

                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </>

  )
}

export { EmployeeEdit }
