import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../helpers'
import { Dropdown1, Search } from '../../../../partials'
import { useAuth } from '../../../../../app/modules/auth';

const projects: ReadonlyArray<{ image: string; title: string; role: string; link: string }> = [
  {
    image: 'fa-solid fa-car',
    title: 'Véhicules',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-user',
    title: 'Clients',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-helmet-safety',
    title: 'Employees',
    role: 'MANAGER',
    link: '/employees',
  },
  {
    image: 'fa-solid fa-coins',
    title: 'Fournisseurs',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-newspaper',
    title: 'Articles',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-people-group',
    title: 'Mains d\'oeuvre',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-box',
    title: 'Forfaits',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-cube',
    title: 'Commandes',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-dollar-sign',
    title: 'Devis',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-clock',
    title: 'Ordre Réparation',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-file-invoice-dollar',
    title: 'Factures',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-file',
    title: 'Avoirs',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-calendar-days',
    title: 'Agenda',
    role: '',
    link: '',
  },
  {
    image: 'fa-solid fa-calendar-day',
    title: 'Planning Travail',
    role: '',
    link: '',
  },
]

const ProjectsTab = () => {
  const { currentUser } = useAuth()

  return (
    <div className='m-0'>
      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}
        <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Projects</h1>
        {/*end::Heading*/}

        {/*begin::Items*/}
        <div className='mb-10'>
          {projects.filter(x => (currentUser?.role != "MANAGER" && x.role != "MANAGER") || currentUser?.role == "MANAGER").map((p) => (
            <Link
              key={p.title}
              to={p.link}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label'>
                  <i className={p.image + " text-primary"} style={{ fontSize: '20px' }}></i>
                </span>
              </div>
              {/*end::Symbol*/}

              {/*begin::Description*/}
              <div className='d-flex flex-column flex-grow-1'>
                {/*begin::Title*/}
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5>
                {/*end::Title*/}

                {/*begin::Link*/}
                {/* <span className='text-gray-400 fw-bold'>{currentUser?.role}</span> */}
                {/*end::Link*/}
              </div>
              {/*begin::Description*/}
            </Link>
          ))}
        </div>
        {/*end::Items*/}
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export { ProjectsTab }
