/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Select from 'react-select';
import { Link } from 'react-router-dom'
import { PasswordMeterComponent, StepperComponent } from '../../../../_metronic/assets/ts/components'

import { REGISTER_URL, Verify_URL } from '../core/_requests'
import axios from 'axios'
import { useAuth } from '../core/Auth'
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  phoneNumber: '',
  site: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: 'Morocco',
  companyName: '',
  OrganizationName: '',
  activityTypeId: "1",
  code: '',
}

interface initialValuesObj {
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  changepassword: string,
  acceptTerms: boolean,
  phoneNumber: string,
  site: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  companyName: string,
  OrganizationName: string,
  activityTypeId: string,
  code: string,
}

const ActivityTypes = [{ label: "Global", value: "1" }, { label: "Vehicle", value: "2" }, { label: "Machine", value: "3" }, { label: "Device", value: "4" }];

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  OrganizationName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Organization Name is required'),
  activityTypeId: Yup.string()
    .required('activityTypeId Name is required'),
  acceptTerms: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
})

export function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [FormikStatus, setFormikStatus] = useState("")
  const [verify, setverify] = useState(false)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const { saveAuth, setCurrentUser } = useAuth()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const submitStep = async (values: initialValuesObj, actions: FormikValues) => {
    setLoading(true)

    try {

      if (verify) {
        const { data: auth } = await axios.post<any>(Verify_URL, {
          email: values.email,
          code: values.code,
        });
        console.log('auth', auth)
        let AuthObj = {
          "email": auth.data.email,
          "expiresOn": new Date(),
          "firstName": auth.data.firstName,
          "isAuthenticated": auth.data.isAuthenticated,
          "lastName": auth.data.lastName,
          "message": auth.data.message,
          "profilePicture": auth.data.profilePicture,
          "roles": auth.data.roles,
          "username": auth.data.username,
          "token": auth.data.token,
          "role": auth.data.role,
        }
        window.localStorage.setItem("AuthObj", JSON.stringify(AuthObj))
        saveAuth(AuthObj)
        setCurrentUser({
          "id": 2,
          "profilePicture": auth.data.profilePicture,
          "email": auth.data.username,
          "first_name": auth.data.firstName,
          "last_name": auth.data.lastName,
          "fullname": auth.data.firstName + " " + auth.data.lastName,
          "companyName": auth.data.username,
          "phone": "string",
          "roles": auth.data.roles,
          "language": 'en',
          "website": 'https://keenthemes.com',
          "auth": auth.data,
          "token": auth.data.token,
          "role": auth.data.role,
        })
        navigate(`/employees`);
      }
      else {
        await axios.post(REGISTER_URL, {
          email: values.email,
          organizationName: values.OrganizationName,
          activityTypeId: +values.activityTypeId,
        })
        setFormikStatus("")
        setverify(true);
        setLoading(false)
      }


    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.errors) {
          var errors = ""
          var ViewModelErrors = error.response?.data.errors
          for (const [key, value] of Object.entries(ViewModelErrors)) {
            errors = errors + key + ": " + (value as Array<string>)[0] + "<br/>"
          }
          setFormikStatus(errors)

        } else {
          setFormikStatus(error.response?.data.message)
        }

      } else {
        console.error(error);
      }
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  return (
    <div>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>

        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-5.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-5.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Facebook
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              style={{ height: "18px" }}
              src={toAbsoluteUrl('/media/svg/brand-logos/tiktok1.svg')}
              className='theme-light-show  me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/tiktok1.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with TikTok
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      <div className='separator separator-content my-6'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>



      <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column'
        id='kt_create_account_stepper'
      >

        {FormikStatus && (
          <div className=' mx-auto mw-600px w-100 alert alert-danger'>
            <div className='alert-text font-weight-bold'
              dangerouslySetInnerHTML={{ __html: FormikStatus }}
            />
            {/* <div >{formik.status}</div> */}
          </div>
        )}
        <Formik validationSchema={registrationSchema} enableReinitialize={false} initialValues={initialValues} onSubmit={submitStep}>
          {({
            values,
            status,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className='mx-auto mw-600px w-100 pt-6 pb-10' id='kt_create_account_form'>

              <div className='current' data-kt-stepper-element='content'>
                <div className='row col-12'>

                  {/* begin::Form group Email */}
                  {!verify &&
                    <>
                      <div className='fv-row mb-8'>
                        <div className="fv-row">
                          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
                          <input
                            placeholder='Email'
                            type='email'
                            autoComplete='off'
                            value={values.email}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value)
                            }}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.email && errors.email },
                              {
                                'is-valid': touched.email && !errors.email,
                              }
                            )}
                          />
                          {touched.email && errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.email}</span>
                              </div>
                            </div>
                          )}
                        </div>

                      </div>

                      {/* begin::Form group Password */}
                      <div className='fv-row mb-6' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Organization Name</label>
                          <div className='position-relative'>
                            <input
                              type='Organization Name'
                              placeholder='Organization Name'
                              autoComplete='off'
                              value={values.OrganizationName}
                              onChange={(e) => {
                                setFieldValue("OrganizationName", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.OrganizationName && errors.OrganizationName,
                                },
                                {
                                  'is-valid': touched.OrganizationName && !errors.OrganizationName,
                                }
                              )}
                            />
                            {touched.OrganizationName && errors.OrganizationName && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.OrganizationName}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                      >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                      </div> */}
                          {/* end::Meter */}
                        </div>
                        {/* <div className='text-muted'>
                      Use 8 or more characters with a mix of letters, numbers & symbols.
                    </div> */}
                      </div>

                      <div className='mb-10'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Activity Type</label>

                          <Select
                            isDisabled={verify}
                            className="basic-single"
                            classNamePrefix="select"
                            value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              setFieldValue("activityTypeId", e?.value)
                            }}
                            isSearchable={true}
                            name="activityTypeId"
                            options={ActivityTypes}
                          />
                          {touched.activityTypeId && errors.activityTypeId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.activityTypeId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='kt_login_toc_agree'
                          checked={values.acceptTerms}
                          onChange={(e) => {
                            setFieldValue("acceptTerms", e.target.checked)
                          }}
                        />
                        <span>
                          I Accept the{' '}
                          <a
                            href='https://keenthemes.com/metronic/?page=faq'
                            target='_blank'
                            className='ms-1 link-primary'
                          >
                            Terms
                          </a>
                          .
                        </span>
                      </label>
                      {touched.acceptTerms && errors.acceptTerms && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.acceptTerms}</span>
                          </div>
                        </div>
                      )}
                    </>}



                  {verify &&
                    <>
                      <div className={`fv-row mb-8`} style={{ opacity: 0 }}>
                        <input
                          placeholder='Email'
                          type='email'
                          autoComplete='off'
                        />
                      </div>
                      <div className='fv-row mb-8'>
                        <div className='text-center'>
                          <div className="pt-lg-10 mb-10">
                            <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">Verify Your Email</h1>
                            <div className="fs-3 fw-bold text-muted mb-10">We have sent an email to
                              <span className="link-primary fw-bolder"> {values.email}</span>
                              <br />please take code from your Email.</div>
                          </div>
                        </div>


                        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
                        <input
                          placeholder='Code'
                          className={clsx(
                            'form-control bg-transparent',
                          )}
                          onChange={(e) => {
                            setFieldValue("code", e.target.value)
                          }}
                          type='text'
                          name='code'
                          autoComplete='off'
                        />
                      </div>
                    </>
                  }

                  {/* end::Form group */}
                </div>
              </div>

              <div className='d-flex flex-stack pt-6'>
                <div className='mr-2'>
                  <button
                    onClick={() => {
                      navigate(`/auth`);

                    }}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    disabled={loading}
                    type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {!loading && 'Submit'}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}

                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
